export const signUpSchema = {
	"@context": "https://schema.org",
	"@type": "WebPage",
	"name": "Sign Up - Start Your SAT Prep Journey",
	"description": "Join our SAT preparation platform to access premium content, practice tests, and personalized study plans.",
	"url": "https://eduprepme.com/signup",
	"logo": "https://www.eduprepme.com/img/logo.png",
	"sameAs": [
		"https://www.facebook.com/profile.php?id=61568659800252&mibextid=ZbWKwL",
		"https://x.com/EduPrepMe/",
		"https://www.linkedin.com/company/eduprepme/",
		"https://www.instagram.com/edu_prep_me",
	],
	"address": {
		"@type": "PostalAddress",
    "streetAddress": "43241 Coit Avenue",
    "addressLocality": "Fremont",
    "addressRegion": "CA",
    "postalCode": "94539",
    "addressCountry": "US"
	},
	"contactPoint": {
		"@type": "ContactPoint",
    "telephone": "+1-408-829-9244",
    "contactType": "Customer Service"
	}
};
export const mainPageSchema = {
	"@context": "https://schema.org",
	"@type": "EducationalOrganization",
	"name": "EduPrepMe: Free AI-Powered SAT Practice Tests",
	"description": "Boost your SAT score with free, unlimited, AI-personalized tests. Study smarter and achieve success—join now!",
	"url": "https://eduprepme.com",
	"logo": "https://www.eduprepme.com/img/logo.png",
	"sameAs": [
		"https://www.facebook.com/profile.php?id=61568659800252&mibextid=ZbWKwL",
		"https://x.com/EduPrepMe/",
		"https://www.linkedin.com/company/eduprepme/",
		"https://www.instagram.com/edu_prep_me",
	],
	"address": {
		"@type": "PostalAddress",
    "streetAddress": "43241 Coit Avenue",
    "addressLocality": "Fremont",
    "addressRegion": "CA",
    "postalCode": "94539",
    "addressCountry": "US"
	},
	"contactPoint": {
		"@type": "ContactPoint",
    "telephone": "+1-408-829-9244",
    "contactType": "Customer Service"
	}
};
export const aboutUsSchema = {
	"@context": "https://schema.org",
	"@type": "AboutUsPage",
	"name": "About EduPrepMe: Revolutionizing SAT Prep for All",
	"description": "Learn how EduPrepMe combines AI-driven tools and quality content to provide free SAT prep for every student.",
	"url": "https://www.eduprepme.com/aboutus",
	"mainEntity": {
		"@type": "Organization",
		"name": "EduPrepMe",
		"url": "https://eduprepme.com",
		"logo": "https://www.eduprepme.com/img/logo.png",
		"founder": {
			"@type": "Person",
			"name": ""
		},
		"sameAs": [
			"https://www.facebook.com/profile.php?id=61568659800252&mibextid=ZbWKwL",
			"https://x.com/EduPrepMe/",
			"https://www.linkedin.com/company/eduprepme/",
			"https://www.instagram.com/edu_prep_me",
		]
	}
};
export const faqSchema = {
	"@context": "https://schema.org",
	"@type": "FAQPage",
	"name": "EduPrepMe FAQs: Your Digital SAT Prep Questions Answered",
	"description": "Find answers to common SAT prep questions at EduPrepMe. Access free, AI-powered tools to excel in the SAT!",
	"url": "https://www.eduprepme.com/faq",
	mainEntity: [],
};
export const methodologySchema = {
	"@context": "https://schema.org",
	"@type": "WebPage",
	"name": "EduPrepMe Methodology: AI-Driven Digital SAT Prep",
	"description": "Discover EduPrepMe's adaptive AI methodology for free, personalized SAT prep. Bridge the gap to success!",
	"url": "https://eduprepme.com/methodology",
	"mainEntity": {
		"@type": "EducationalOrganization",
		"name": "EduPrepMe",
		"description": "At EduPrepMe, we recognize the critical role that the SAT continues to play in the holistic college admissions process. Test scores, when considered alongside the context of a student's educational and personal background, provide valuable insights into a student's academic potential. They can affirm the hard work reflected in their grades or reveal strengths that go beyond the classroom performance."
	}
};
export const privacySchema = {
	"@context": "https://schema.org",
	"@type": "WebPage",
	"name": "EduPrepMe Privacy Policy | Your Data, Our Responsibility",
	"description": "Learn how EduPrepMe protects your data and ensures privacy. Review our updated Privacy Policy today!",
	"url": "https://eduprepme.com/privacy"
};
export const teamsSchema = {
	"@context": "https://schema.org",
	"@type": "AboutPage",
	"name": "Meet the EduPrepMe Team: Innovators Behind Free SAT Prep",
	"description": "Discover the passionate EduPrepMe team dedicated to making SAT prep accessible for all students. Meet our experts!",
	"url": "https://eduprepme.com/teams",
	"mainEntity": {
		"@type": "Organization",
		"name": "EduPrepMe",
		"employee": []
	}
};
export const termsSchema = {
	"@context": "https://schema.org",
	"@type": "WebPage",
	"name": "EduPrepMe Terms and Conditions | Your Guide to Using Our Services",
	"description": "Read EduPrepMe's Terms and Conditions to understand your rights and responsibilities while using our SAT prep platform.",
	"url": "https://eduprepme.com/terms"
};
export const thankYouSchema = {
	"@context": "https://schema.org",
	"@type": "WebPage",
	"name": "Thank You for Signing Up!",
	"description": "Your registration was successful.",
	"url": "https://eduprepme.com/thankyou",
	"mainEntity": {
		"@type": "EducationalOrganization",
		"name": "EduPrepMe",
		"description": "At EduPrepMe, we recognize the critical role that the SAT continues to play in the holistic college admissions process. Test scores, when considered alongside the context of a student's educational and personal background, provide valuable insights into a student's academic potential. They can affirm the hard work reflected in their grades or reveal strengths that go beyond the classroom performance."
	}
};