
import './css/custom.css'
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { TestProvider } from './TextContext';
import ProtectedRoute from './components/ProtectedRoute';
import Toast from "./components/actionNotice";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Loader from './components/Loader';
import { mainPageSchema } from './utils/seo-schemas';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { addRecaptchaScript, addTWAKScript } from './utils';

const MainPage = React.lazy(() => import('./MainPage'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const Questions = React.lazy(() => import('./Questions'));
const Teams = React.lazy(() => import('./Teams'));
const Login = React.lazy(() => import('./Login'));
const OnlineTest = React.lazy(() => import('./OnlineTest'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const News = React.lazy(() => import('./News'));
const Blogs = React.lazy(() => import('./Blogs'));
const OurMethodology = React.lazy(() => import('./OurMethodology'));
const Terms = React.lazy(() => import('./Terms'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const Privacy = React.lazy(() => import('./Privacy'));
const Faq = React.lazy(() => import('./Faq'));
const StudentDashboard = React.lazy(() => import('./StudentDashboard'));
const Signup = React.lazy(() => import('./Signup'));
const ThankYou = React.lazy(() => import('./ThankYou'));


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(localStorage.getItem('student-token'));

  useEffect(() => {
    addRecaptchaScript()
    if (!window.location.pathname.includes('/questions')) {
      addTWAKScript()
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('student-token');
    setIsLoggedIn(!!token); // Simplified setting of isLoggedIn
    setIsLoading(false);
  }, [session]);

  if (isLoading) {
    return <Loader />
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <TestProvider>
        <Router>
          <div className="app-container">
            <Helmet>
              <meta charSet="utf-8" />
              <title>EduPrepMe: Free AI-Powered SAT Practice Tests</title>
              <meta name="description" content="Boost your SAT score with free, unlimited, AI-personalized tests. Study smarter and achieve success—join now!" />
              <script type="application/ld+json">
                  {JSON.stringify(mainPageSchema)}
              </script>
              <meta property="og:site_name" content="Eduprepme" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:image" content="https://www.eduprepme.com/img/logo.png" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="EduPrepMe: Free AI-Powered SAT Practice Tests" />
              <meta property="og:description" content="Boost your SAT score with free, unlimited, AI-personalized tests. Study smarter and achieve success—join now!" />
              <meta property="og:url" content="https://eduprepme.com" />
            </Helmet>
            <div className="main-content">
              <div className="content">
                <Toast />
                <Routes>
                  <Route path="/" element={<React.Suspense fallback={Loader}>
                    <MainPage />
                  </React.Suspense>} />
                  <Route path="/login" element={!isLoggedIn ? <React.Suspense fallback={Loader}>
                    <Login />
                  </React.Suspense> : <Navigate to="/" />} />
                  <Route path="/signup" element={!isLoggedIn ? <React.Suspense fallback={Loader}>
                    <Signup />
                  </React.Suspense> : <Navigate to="/" />} />
                  <Route path="/dashboard" element={<React.Suspense fallback={Loader}>
                    <Dashboard />
                  </React.Suspense>} />
                  <Route path="/teams" element={<React.Suspense fallback={Loader}>
                    <Teams />
                  </React.Suspense>} />
                  <Route path="/methodology" element={<React.Suspense fallback={Loader}>
                    <OurMethodology />
                  </React.Suspense>} />
                  <Route path="/terms" element={<React.Suspense fallback={Loader}>
                    <Terms />
                  </React.Suspense>} />
                  <Route path="/privacy" element={<React.Suspense fallback={Loader}>
                    <Privacy />
                  </React.Suspense>} />
                  <Route path="/aboutus" element={<React.Suspense fallback={Loader}>
                    <AboutUs />
                  </React.Suspense>} />
                  {/* <Route path="/news" element={<News />} /> */}
                  <Route path="/blogs" element={<React.Suspense fallback={Loader}>
                    <Blogs />
                  </React.Suspense>} />
                  <Route path="/faq" element={<React.Suspense fallback={Loader}>
                    <Faq />
                  </React.Suspense>} />
                  <Route path="/thankyou" element={<React.Suspense fallback={Loader}>
                    <ThankYou />
                  </React.Suspense>} />
                  <Route path="/forgot-password" element={<React.Suspense fallback={Loader}>
                    <ForgotPassword />
                  </React.Suspense>} />
                  <Route
                    path="/student/dashboard"
                    element={<React.Suspense fallback={Loader}>
                      <StudentDashboard />
                    </React.Suspense>}
                  />
                  {/* <Route
                    path="/online-test"
                    element={
                      // <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <OnlineTest />
                      //  </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path="/questions"
                    element={<React.Suspense fallback={Loader}>
                      <Questions />
                    </React.Suspense>}
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          </div>
        </Router>
      </TestProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
